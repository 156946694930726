.suggestion-item, .suggestion-item--active {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color:#ccc;
  margin-top: 10px;

}
.suggestion-item--active{
  background-color: #fd000066;
}
.sliderImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  /* min-height: 150px; */
}

@media (max-width: 767px) {
  .center-mobile {
    text-align: center;
  }
  
  .center-mobile .hero_btn {
    justify-content: center;
    align-items: center;
  }
  .center-mobile .btn-center, .btn-center{
    justify-content: center;
  }
  .sliderImage {
   min-height: 190px;
   max-height: 200px;
  }
  #menusearch {
    padding:"23px 15px"
  }
}

@media (min-width: 767px) and (max-width:991px) {
  .hero_area .hero_height  {
    min-height: 500px!important;
  }
  .sliderImage {
    min-height: 400px;
    max-height: 500px;
  }
  #menusearch {
    padding:"25px 15px"
  }
}


@media (min-width: 991px) {
  .mobile_only {
display: none;
  }
  .sliderImage {
    min-height: 500px;
    max-height: 850px;
  }
  #menusearch {
    padding:"25px 15px"
  }
}