.dflex{
    display: flex;
}
.align-center{
    align-items: center;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.justify-around{
    justify-content: space-around;
}
.summary_item{
    border-bottom: 2px solid #fff;
    padding-bottom: 15px;
}
.no-border{
    border:none;
}



#progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: rgb(218, 0, 0);
    padding-left: 0px;
    margin-top: 3vh
}

#progressbar li {
    list-style-type: none;
    font-size: 16px;
    /* width: 25%; */
    float: left;
    position: relative;
    font-weight: 400;
    color: rgb(160, 159, 159);
}

#progressbar #step1:before {
    content: "";
    color: rgb(218, 0, 0);
    width: 5px;
    height: 5px;
    margin-left: 0px !important;
    /* padding-left: 11px !important */
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-left: 32%;
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 32% ; 
    /* padding-right: 11px !important */
}

#progressbar #step4:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 0px !important;
    /* padding-right: 11px !important */
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #ddd;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh;
}

#progressbar li:after {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 1px;
    z-index: 1;
}
.progress-track{
    padding: 0 8%;
}
#progressbar li:nth-child(2):after {
    margin-right: auto;
}

#progressbar li:nth-child(1):after {
    margin: auto;
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%;
}
#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%;
}

#progressbar  li.active{
    color: black;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: rgb(218, 0, 0);
}


.order-item-link {
    margin-top: 15px;
    margin-bottom: 15px;
}
.bkfst {
    font-size: 20px;
    /* padding-left: 20px; */
}
.fixedFilter {
    position: fixed;
    width: 83%;
    z-index: 9999;
    background-color: #fff;
    padding:5px;
    top: 0;
    /* filterBar.style.position = 'fixed';
    filterBar.style.width = '80%'
    filterBar.style.zIndex = '9999'
    filterBar.style.backgroundColor = 'white'
    filterBar.style.padding = "15px" */
}